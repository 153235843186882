import React, { Component } from 'react'
import './css/style.css';
import Index from './jsx';
import { Lines } from 'react-preloaders';

import { UserProvider } from "./contexts/User"

class App extends Component {

  render(){

    return (
      <UserProvider>
        <div className="App">
          <Index />
          <Lines />  
        </div>
      </UserProvider>
    )
  }
}

export default App