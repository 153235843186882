import React from "react";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import YouTube from 'react-youtube';

import PageTitle from "../element/page-title";
import Popup from "../element/popup";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";

function Pay() {
    const opts = {
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        loop: 1,
        playlist: 'RSGt0MKho98',
      },
    };

    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <h3>Coming soon</h3>
                            <YouTube
                              videoId="RSGt0MKho98"
                              opts={opts}
                            />
                        </div>
                    </div>

                    
                </div>
            </div>
        </>
    );
}

export default Pay;
