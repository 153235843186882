import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logo_bl.png';

function Sidebar() {

    return (
        <>
            <div className="sidebar">
                <Link className="brand-logo" to={"/"}>
                    <img src={logo} style={{width:170}} />
                </Link>
                <div className="menu">
                    <ul>
                        <li>
                            <Link to={"/"}>
                                <span><i className="mdi mdi-view-dashboard"></i></span>
                                <span className="nav-text">Home</span>
                            </Link>
                        </li>
                        <li><Link to={"./pay"}>
                            <span><i className="mdi mdi-send"></i></span>
                            <span className="nav-text">Pay</span>
                        </Link>
                        </li>
                        {/*
                        <li><Link to={"./accounts"}>
                            <span><i className="mdi mdi-gift"></i></span>
                            <span className="nav-text">Rewards</span>
                        </Link>
                        </li>
                        <li><Link to={"./buy-sell"}>
                            <span><i className="mdi mdi-send"></i></span>
                            <span className="nav-text">Pay</span>
                        </Link>
                        </li>
                        <li><Link to={"./buy-sell"}>
                            <span><i className="mdi mdi-stackexchange"></i></span>
                            <span className="nav-text">Stake</span>
                        </Link>
                        </li>
                        <li><Link to={"./settings"}>
                            <span><i className="mdi mdi-settings"></i></span>
                            <span className="nav-text">Setting</span>
                        </Link>
                        </li>
                    */}
                    </ul>
                </div>
                <div className="sidebar-footer">
                    <div className="social">
                        <a href={"https://t.me/bnbpay"} target="_blank"><i className="fa fa-telegram"></i></a>
                        <a href={"https://twitter.com/bnbpay"} target="_blank"><i className="fa fa-twitter"></i></a>
                        <a href={"https://www.instagram.com/bnbpay/"} target="_blank"><i className="fa fa-instagram"></i></a>
                        <a href={"https://www.youtube.com/channel/UCcYgWaX7vvhjsewd7-d599w"} target="_blank"><i className="fa fa-youtube-play"></i></a>
                        <a href={"https://www.facebook.com/bnbpay"} target="_blank"><i className="fa fa-facebook"></i></a>
                    </div>
                    <div className="copy_right">
                        © {new Date().getFullYear()} BNBPay
                </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;