import React, { useState, useEffect, useCallback } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from "../../contexts/User";
import connectMetamaskWallet from '../../web3/connectMetamaskWallet';
import disconnectMetamaskWallet from '../../web3/disconnectMetamaskWallet';
import getWeb3 from '../../web3/getWeb3';

import WalletConnectProvider from '@walletconnect/web3-provider';
import { providers } from 'ethers';
import WalletLink from 'walletlink';
import Web3Modal from 'web3modal';
import { ellipseAddress, getChainData } from '../../lib/utilities';

import logo from '../../images/logo_bl.png';

const INFURA_ID = '679be12b168f444585254ff4874aaa9a'

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'Coinbase', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options
      const walletLink = new WalletLink({
        appName,
      })
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
      await provider.enable()
      return provider
    },
  },
}

let web3Modal
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })
}

const Header2 = (): JSX.Element => {
    const [ state, dispatch ] = React.useContext(UserContext)
    const { provider, web3Provider, address, chainId } = state
    //const [ web3, setWeb3 ] = useState({});

    const connect = useCallback(async function () {
        // This is the initial `provider` that is returned when
        // using web3Modal to connect. Can be MetaMask or WalletConnect.
        const provider = await web3Modal.connect()

        // We plug the initial `provider` into ethers.js and get back
        // a Web3Provider. This will add on methods from ethers.js and
        // event listeners such as `.on()` will be different.
        const web3Provider = new providers.Web3Provider(provider)

        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()

        const network = await web3Provider.getNetwork()

        dispatch({
          type: 'SET_WEB3_PROVIDER',
          provider,
          web3Provider,
          address,
          chainId: network.chainId,
        })
    }, [])

    const disconnect = useCallback(
        async function () {
          await web3Modal.clearCachedProvider()
          if (provider?.disconnect && typeof provider.disconnect === 'function') {
            await provider.disconnect()
          }
          dispatch({
            type: 'RESET_WEB3_PROVIDER',
          })
        },
        [provider]
    )

    // Auto connect to the cached provider
    useEffect(() => {
        if (web3Modal.cachedProvider) {
          connect()
        }
    }, [connect])

    // A `provider` should come with EIP-1193 events. We'll listen for those events
    // here so that when a user switches accounts or networks, we can update the
    // local React state with that new information.
    useEffect(() => {
        if (provider?.on) {
          const handleAccountsChanged = (accounts: string[]) => {
            // eslint-disable-next-line no-console
            //console.log('accountsChanged', accounts)
            dispatch({
              type: 'SET_ADDRESS',
              address: accounts[0],
            })
          }

          const handleChainChanged = (accounts: string[]) => {
            // eslint-disable-next-line no-console
            //console.log('accountsChanged', accounts)
            dispatch({
              type: 'SET_ADDRESS',
              address: accounts[0],
            })
          }

          const handleDisconnect = (error: { code: number; message: string }) => {
            // eslint-disable-next-line no-console
            //console.log('disconnect', error)
            disconnect()
          }

          provider.on('accountsChanged', handleAccountsChanged)
          provider.on('chainChanged', handleChainChanged)
          provider.on('disconnect', handleDisconnect)

          // Subscription Cleanup
          return () => {
            if (provider.removeListener) {
              provider.removeListener('accountsChanged', handleAccountsChanged)
              provider.removeListener('chainChanged', handleChainChanged)
              provider.removeListener('disconnect', handleDisconnect)
            }
          }
        }
    }, [provider, disconnect])

    const chainData = getChainData(chainId)

    return (
        <>
            <div className="header dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                <Link className="navbar-brand" to={'/'}><img src={logo} className="d-md-none" style={{width:170}} /></Link>

                                <div className="dashboard_log my-2">
                                    <div className="d-flex align-items-center">
                                        {web3Provider ? 
                                            <DropdownButton
                                                alignRight
                                                title={address && ellipseAddress(address, 5)}
                                                className="profile_log"
                                            >
                                                <a onClick={disconnect} className="dropdown-item logout">
                                                    <i className="la la-sign-out"></i> Disconnect
                                                </a>
                                            </DropdownButton>
                                            :
                                            <button
                                                type="submit"
                                                name="submit"
                                                className="btn btn-success"
                                                onClick={connect}
                                            >
                                                Connect
                                            </button>
                                        }
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header2;